import { createNamespacedHelpers } from 'vuex';
import tools from '../libs/tools';

const contStore = createNamespacedHelpers('Cont');

export default {
    mounted(){
        this.getSpeakers();
    },
    data(){
        return {
            dd_speakers: []
        };
    },
    computed: {
        ...contStore.mapGetters(['Speakers/rows', 'Speakers/loading']),
        speakersRefreshLoadingIcon(){
            return this['Speakers/loading'] ? "pi pi-spin pi-spinner" : "pi pi-refresh";
        }
    },
    methods: {
        ...contStore.mapActions(['Speakers/fetchList']),
        initSpeakers(items){
            this.dd_speakers = tools.models.listsToDropdown(items);
        },
        refreshSpeakers(){
            this['Speakers/fetchList']().then(items => {
                this.initSpeakers(items);
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        getSpeakers(){
            if (this['Speakers/rows'] && this['Speakers/rows'].length){
                this.initSpeakers(this['Speakers/rows']);
            } else {
                this.refreshSpeakers();
            }
        }

    }
}
