import tools from '../../../libs/tools';
import { minLength, required, url } from '@vuelidate/validators';

const model = {
    id: "",
    s_cats: "",
    s_aut: "",
    categories: [],
    authors: [],
    tags: [],
    slug: "",
    last_update: 0,
    translations: {}
};
const translation = {
    aid: "",
    alt: "", //
    audio: {
        url: "",
        seconds: 0,
    },
    books: [],
    free: false,
    catchline: "", //
    chapters: [],
    cover_url: "", //
    file: "",
    l: "",
    keywords: [],
    pub_at : null,
    publish_state: "",
    purpose: "", //
    slug: "",
    seo: {
        title: "",
        description: "",
        keywords: []
    },
    copywriter_id: null,
    speaker_id: null,
    last_update: 0,
    subtitle: "", //
    summary: "", //
    title: "", //
};



export default {
    ...tools.models.apiCommons(model, translation, "aid"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            authors: {
                required,
                minLength: minLength(1)
            },
            tags: {
                required,
                minLength: minLength(1)
            },
            categories: {
                required,
                minLength: minLength(1)
            }
        }
    },
    translationValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            catchline: { required },
            purpose: { required },
            publish_state: { required },
            cover_url: {
                required,
                url
            },
            title: { required },
            pub_at: {
                validDate: tools.models.validators.PUBLISH_DATE
            }
        }
    },
    uploads: [
        { el: "uCoverUrl", target: "cover_url", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
        { el: "uAudioUrl", target: "audio.url", folder: "/audio/%L/%Y/%M", protect: true, urlChanger: url => url.replace('/audio/','/'), maxNum: 1, types: [tools.models.uploadFileTypes.SOUND]},
    ]

};
