import { createNamespacedHelpers } from 'vuex';
import tools from '../libs/tools';

const contStore = createNamespacedHelpers('Cont');

export default {
    mounted(){
        this.getCopywriters();
    },
    data(){
        return {
            dd_copywriters: []
        };
    },
    computed: {
        ...contStore.mapGetters(['Copywriters/rows', 'Copywriters/loading']),
        copywritersRefreshLoadingIcon(){
            return this['Copywriters/loading'] ? "pi pi-spin pi-spinner" : "pi pi-refresh";
        }
    },
    methods: {
        ...contStore.mapActions(['Copywriters/fetchList']),
        initCopywriters(items){
            this.dd_copywriters = tools.models.listsToDropdown(items);
        },
        refreshCopywriters(){
            this['Copywriters/fetchList']().then(items => {
                this.initCopywriters(items);
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        getCopywriters(){
            if (this['Copywriters/rows'] && this['Copywriters/rows'].length){
                this.initCopywriters(this['Copywriters/rows']);
            } else {
                this.refreshCopywriters();
            }
        }

    }
}
