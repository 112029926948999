import { createNamespacedHelpers } from 'vuex';
const contStore = createNamespacedHelpers('Cont');
import tools from '../libs/tools';

export default {
    mounted(){
        this.getCategories();
    },
    data(){
        return {
            dd_categories: []
        };
    },
    computed: {
        ...contStore.mapGetters(['Categories/rows', 'Categories/loading']),
        categoriesRefreshLoadingIcon(){
            return this['Categories/loading'] ? "pi pi-spin pi-spinner" : "pi pi-refresh";
        }
    },
    methods: {
        ...contStore.mapActions(['Categories/fetchList']),
        initCategories(cats){
            this.dd_categories = tools.models.listsToDropdown(cats);
        },
        refreshCategories(){
            this['Categories/fetchList']().then(cats => {
                this.initCategories(cats);
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        getCategories(){
            if (this['Categories/rows'] && this['Categories/rows'].length){
                this.initCategories(this['Categories/rows']);
            } else {
                this.refreshCategories();
            }
        }

    }
}
