import { createNamespacedHelpers } from 'vuex';
const contStore = createNamespacedHelpers('Cont');
import tools from '../libs/tools';

export default {
    mounted(){
        this.getTags();
    },
    data(){
        return {
            dd_tags: []
        };
    },
    computed: {
        ...contStore.mapGetters(['Tags/rows', 'Tags/loading']),
        tagsRefreshLoadingIcon(){
            return this['Tags/loading'] ? "pi pi-spin pi-spinner" : "pi pi-refresh";
        }
    },
    methods: {
        ...contStore.mapActions(['Tags/fetchList']),
        initTags(items){
            this.dd_tags = tools.models.listsToDropdown(items);
        },
        refreshTags(){
            this['Tags/fetchList']().then(cats => {
                this.initTags(cats);
            }).catch(err => {
                this.ShowStandardError(err.response);
            });

        },
        getTags(){
            if (this['Tags/rows'] && this['Tags/rows'].length){
                this.initTags(this['Tags/rows']);
            } else {
                this.refreshTags();
            }
        }

    }
}
