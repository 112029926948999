<template>
    <Card>
        <template #title>
            {{ title }}
        </template>
        <template #content>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='searchline'
                                   type='text'
                                   v-model="searchLine"
                        />
                        <label for='searchline'>Cerca per titolo / slug / autore</label>
                    </span>
                </div>
            </div>
            <!-- Search results -->
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <DataView :value="boxes" layout="grid" :rows="limit">
                        <template #empty>Non ci sono risultati</template>
                        <template #grid="slotProps">
                            <div class="col-12 md:col-4">
                                <Card :style='{width: "95%"}'>
                                    <template #header>
                                        <img v-if="getCover(slotProps.data)" :src="getCover(slotProps.data)" :alt="slotProps.data.slug" height='200'/>
                                    </template>
                                    <template #content>
                                        <div v-if='getCategories(slotProps.data)'>
                                            <i class="pi pi-tag product-category-icon"></i> {{getCategories(slotProps.data)}}
                                        </div>
                                        <h5>{{getTitle(slotProps.data)}}</h5>
                                        <div class="product-description">Autori: {{getAuthors(slotProps.data)}}</div>
                                        <div class="product-description">
                                            <div v-for='(tt, ll) in slotProps.data.translations' :key='slotProps.data.id+"-"+ll'>
                                                <Flag :code="ll" :name="ll" :class="{'mr-1': true}"/>
                                                <span :style='{color: getPublishState(tt).color}'>{{getPublishState(tt).text}}</span>
                                            </div>
                                        </div>
                                    </template>
                                    <template #footer>
                                        <Button icon="pi pi-plus" label='Aggiungi' :style='{width: "100%"}' @click='$emit("bookAdded", slotProps.data)'/>
                                    </template>
                                </Card>
                            </div>
                        </template>
                    </DataView>
                </div>
            </div>
            <!-- books added -->
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12'>
                    <DataTable
                        :value="books"
                        :scrollable="true"
                        :scrollHeight="th"
                        scrollDirection="both"
                        class="mt-3 p-shadow-1">
                        <template #empty>
                            Non ci sono libri correlati.
                        </template>
                        <Column header="Titolo" :style="{width:'50%'}">
                            <template #body="{data}">
                                <span>{{getTitle(data)}}</span>
                            </template>
                        </Column>
                        <Column header="Codice" :style="{width:'35%'}">
                            <template #body="{data}">
                                <span @click='copyText(getEmbedCode(data))' class='pointer' title='Copia'><i class="pi pi-copy"></i> {{getEmbedCode(data)}}</span>
                            </template>
                        </Column>
                        <Column header="" :style="{width:'10%'}">
                            <template #body="{data}">
                                <Button icon="pi pi-minus" class="p-button-danger" @click='$emit("bookRemoved", data, getEmbedCode(data))'/>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/Books');
import Notifications from '../../mixins/notifications';
import Navigations from '../../mixins/navigations';
import tools from '../../libs/tools';

export default {
    data(){
        return {
            searchLine: ""
        };
    },
    mounted(){
      this.clearSearch();
    },
    computed: {
        ...mapGetters(['searched']),
        boxes(){
            return this.searched.filter(b => {
                return !this.mapped[b.id];
            }); //Remove already added books
        },
        mapped(){
            const books = {};
            this.books.forEach(b => {
                books[b.id] = 1;
            })
            return books;
        }
    },
    emits: ['bookAdded', 'bookRemoved'],
    methods: {
        ...mapMutations(['clearSearch']),
        ...mapActions(['search']),
        getCover(row){
            if (row.translation && row.translation["cover_url"]) return row.translation["cover_url"];
            return "";
        },
        getTitle(row){
            if (row.translation){
                if (row.translation.title) return row.translation.title;
                if (row.translation.slug) return row.translation.slug;
            }
            if (row.slug){
                return row.slug;
            }
            return "Titolo sconosciuto";
        },
        getPublishState(row){
            let color = "red";
            if (!row.publish_state) return {
                text: "Assente",
                color: color
            };
            let ret = row.publish_state.charAt(0).toUpperCase() + row.publish_state.slice(1);
            if (row.publish_state==="published"){
                color = "green";
                const pdate = tools.time.api2Date(row.pub_at);
                if (pdate > new Date()) color = "orange";
                ret += ' ' + tools.time.dateFormat(pdate);
            } else if (row.publish_state==="ready"){
                color = "orange";
            }
            return {
                text: ret,
                color: color
            };
        },
        getCategories(row){
            if (!row.categories) return "";
            const cats = [];
            row.categories.forEach(cat => {
               if (cat.translation && cat.translation.name) {
                   cats.push(cat.translation.name);
                   return;
               }
               cats.push(cat.slug);
            });
            return cats.join(", ");
        },
        getAuthors(row){
            if (!row.authors) return "";
            const authors = [];
            row.authors.forEach(item => {
                authors.push(item.name);
            });
            return authors.join(", ");
        },
        getEmbedCode(row){
            return tools.models.getBookEmbedCode(row);
        }
    },
    props: {
        books: {
            type: Array,
            default(){
                return [];
            }
        },
        title: {
            type: String,
            default(){
                return "Libri correlati"
            }
        },
        limit: {
            type: Number,
            default(){
                return 12;
            }
        },
        th: {
            type: String,
            default(){
                return "400px";
            }
        }
    },
    mixins: [
        Notifications, Navigations
    ],
    watch: {
        searchLine(n){
            if (n && n.length > 3){
                this.search({
                    text: n
                }).catch(this.ShowApiError);
            } else {
                this.clearSearch();
            }
        }
    },
    beforeRouteLeave(to, from, next){
        this.clearSearch();
        next();
    },
    beforeRouteUpdate(to, from, next){
        this.clearSearch();
        next();
    },
}
</script>
